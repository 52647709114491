
import { defineComponent, onMounted } from "vue";
import { Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n/index";

export default defineComponent({
  name: "customer-financial-statements-cash-flow",
  components: {
    ElCurrencyInput,
    Form,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Cash Flow", [
        "Customer",
        "Financial Statements HH",
      ]);
    });

    const { t, te, n } = useI18n();

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    return { nFormat };
  },
  data() {
    let isLoading = true;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const cstmr_id = route.params.customer_id ?? null;
    const fin_id = route.params.fin_id ?? null;
    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {},
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
      net_worth_secured_liabilities: [],
      net_worth_secured_total: [],
      net_worth_unsecured_liabilities: [],
      net_worth_unsecured_total: [],
    };
    const statements = {
      cashInFlow: {
        label: "Cash Inflow",
        annually: 0,
        monthly: 0,
        child: {
          grossAnnualEmploymentIncome: {
            label: "Gross Annual Employment Income",
            annually: 0,
            monthly: 0,
          },
          grossAnnualEmploymentBonus: {
            label: "Gross Annual Employment Bonus",
            annually: 0,
            monthly: 0,
          },
          epfFromEmployer: {
            label:
              "EPF from Employer (12% Income existing RM5,000 & 13% Income below RM 5,000)",
            annually: 0,
            monthly: 0,
          },
          subTotal: {
            label: "Sub Total",
            annually: 0,
            monthly: 0,
          },
          lessEpfOfEmployee: {
            label: "(Less EPF of Employee) (11% Income)",
            annually: 0,
            monthly: 0,
          },
          lessEpfOfEmployer: {
            label: "(Less EPF of Employer) (12% Income)",
            annually: 0,
            monthly: 0,
          },
          netAnnualIncomeAndBonus: {
            label: "Net Annual Income And Bonus",
            annually: 0,
            monthly: 0,
          },
          returnofInvestment: {
            label: "Return of Investment",
            annually: 0,
            monthly: 0,
          },
          otherIncome: {
            label: "Other Income",
            annually: 0,
            monthly: 0,
          },
        },
      },
      cashOutFlow: {
        label: "Cash Outflow",
        annually: 0,
        monthly: 0,
        child: {
          homeUtilities: {
            label:
              "Home Utilities (e.g. Water, Gas, Paid TV License and others)",
            annually: 0,
            monthly: 0,
          },
          autoMaintenance: {
            label: "Auto Maintenance (services, road tax, petrol ",
            annually: 0,
            monthly: 0,
          },
          carLoanRepayment: {
            label: "Car Loan Repayment",
            annually: 0,
            monthly: 0,
          },
          creditCardRepayment: {
            label: "Credit Card Repayment",
            annually: 0,
            monthly: 0,
          },
          mortgageLoanRepayment: {
            label: "Mortgage Loan Repayment",
            annually: 0,
            monthly: 0,
          },
          otherLoanRepayment: {
            label: "Other Loan Repayment",
            annually: 0,
            monthly: 0,
          },
          savings: {
            label: "Savings",
            annually: 0,
            monthly: 0,
          },
          investment: {
            label:
              "Investment (e.g. Insurance premium / Takaful contribution, Investment / Islamic)",
            annually: 0,
            monthly: 0,
          },
          motorTakaful: {
            label: "Motor Takaful ",
            annually: 0,
            monthly: 0,
          },
          houseownerTakaful: {
            label: "Houseowner Takaful",
            annually: 0,
            monthly: 0,
          },
          quitRentAssessment: {
            label: "Quit Rent, Assessment",
            annually: 0,
            monthly: 0,
          },
          booksMagazinesNewspaper: {
            label: "Books, Magazines, Newspaper etc.",
            annually: 0,
            monthly: 0,
          },
          luxury: {
            label: "Luxury (e.g. Holiday, Clubs)",
            annually: 0,
            monthly: 0,
          },
          otherHobbiesInterest: {
            label: "Other Hobbies/Interest",
            annually: 0,
            monthly: 0,
          },
          familyPersonalLife: {
            label:
              "Family & Personal Life (e.g. Food, Clothing, Child Expenses, Medical)",
            annually: 0,
            monthly: 0,
          },
          parents: {
            label: "Parents",
            annually: 0,
            monthly: 0,
          },
          children: {
            label: "Children",
            annually: 0,
            monthly: 0,
          },
          taxZakat: {
            label: "Tax & Zakat Paid",
            annually: 0,
            monthly: 0,
          },
          otherExpenses: {
            label: "Other Expenses",
            annually: 0,
            monthly: 0,
          },
        },
      },
      netCashFlow: {
        label: "Net Cash Flow",
        annually: 0,
        monthly: 0,
      },
    };

    let fromInAnnual = false;
    // let fromMonthly = false;
    let fromOutAnnual = false;
    // let fromMonthly = false;

    return {
      statements,
      isLoading,
      store,
      router,
      cstmr_id,
      fin_id,
      customer,
      fromInAnnual,
      fromOutAnnual,
      finDetails: {
        raw: {
          statements: {},
        },
      },
    };
  },
  methods: {
    init() {
      if (this.cstmr_id) {
        this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
          this.customer = this.store.getters.getCustomerData;
          this.isLoading = false;
        });
      }

      if (this.fin_id) {
        this.store
          .dispatch(Actions.GET_FINANCIAL_STATEMENT, this.fin_id)
          .then(() => {
            this.statements = this.store.getters.getFinancialStatementData.raw.statements;
          });
      }
    },

    calculateInMonthly(i) {
      this.fromInAnnual = true;
      this.statements.cashInFlow.child[i].monthly =
        this.statements.cashInFlow.child[i].annually / 12;
      this.calculateCashInFlow();
    },

    calculateInAnnually(i) {
      if (!this.fromInAnnual) {
        this.statements.cashInFlow.child[i].annually =
          this.statements.cashInFlow.child[i].monthly * 12;
        console.log(this.statements.cashInFlow.child[i].monthly);
      }
      this.fromInAnnual = false;

      this.calculateCashInFlow();
    },

    calculateCashInFlow() {
      this.statements.cashInFlow.child.subTotal.annually =
        this.statements.cashInFlow.child.grossAnnualEmploymentIncome.annually +
        this.statements.cashInFlow.child.grossAnnualEmploymentBonus.annually +
        this.statements.cashInFlow.child.epfFromEmployer.annually;
      this.statements.cashInFlow.child.subTotal.monthly =
        this.statements.cashInFlow.child.subTotal.annually / 12;

      this.statements.cashInFlow.child.netAnnualIncomeAndBonus.annually =
        this.statements.cashInFlow.child.subTotal.annually -
        this.statements.cashInFlow.child.lessEpfOfEmployee.annually -
        this.statements.cashInFlow.child.lessEpfOfEmployer.annually;

      this.statements.cashInFlow.child.netAnnualIncomeAndBonus.monthly =
        this.statements.cashInFlow.child.netAnnualIncomeAndBonus.annually / 12;

      this.statements.cashInFlow.annually =
        this.statements.cashInFlow.child.netAnnualIncomeAndBonus.annually +
        this.statements.cashInFlow.child.returnofInvestment.annually +
        this.statements.cashInFlow.child.otherIncome.annually;
      this.statements.cashInFlow.monthly =
        this.statements.cashInFlow.annually / 12;

      this.statements.netCashFlow.annually =
        this.statements.cashInFlow.annually -
        this.statements.cashOutFlow.annually;

      this.statements.netCashFlow.monthly =
        this.statements.netCashFlow.annually / 12;
    },

    calculateOutMonthly(i) {
      this.fromOutAnnual = true;
      this.statements.cashOutFlow.child[i].monthly =
        this.statements.cashOutFlow.child[i].annually / 12;
      this.calculateCashOutFlow();
    },

    calculateOutAnnually(i) {
      if (!this.fromOutAnnual) {
        this.statements.cashOutFlow.child[i].annually =
          this.statements.cashOutFlow.child[i].monthly * 12;
        console.log(this.statements.cashOutFlow.child[i].monthly);
      }
      this.fromOutAnnual = false;

      this.calculateCashOutFlow();
    },

    calculateCashOutFlow() {
      this.statements.cashOutFlow.annually = 0;
      for (let cashIn of Object.keys(this.statements.cashOutFlow.child)) {
        this.statements.cashOutFlow.annually += this.statements.cashOutFlow.child[
          cashIn
        ].annually;
      }

      this.statements.cashOutFlow.monthly =
        this.statements.cashOutFlow.annually / 12;

      this.statements.netCashFlow.annually =
        this.statements.cashInFlow.annually -
        this.statements.cashOutFlow.annually;

      this.statements.netCashFlow.monthly =
        this.statements.netCashFlow.annually / 12;

      console.log(this.statements.cashInFlow.annually);
      console.log(this.statements.cashOutFlow.annually);
    },
    onPrint() {
      window.print();
    },
    onSubmit(value) {
      const data = {
        id: this.fin_id,
        user_id: this.cstmr_id,
        created_by: this.store.getters.currentUser.id,
        financial_statement_type_id: 2,
        raw: {
          statements: this.statements,
        },
      };

      if (this.fin_id) {
        this.store
          .dispatch(Actions.UPDATE_FINANCIAL_STATEMENT, data)
          .then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "Cash Flow has been updated!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(() => {
              this.router.push({
                path: `/customers/details/${this.cstmr_id}/financial-statements/hh/list`,
              });
            });
          });
      } else {
        this.store
          .dispatch(Actions.STORE_FINANCIAL_STATEMENT, data)
          .then(() => {
            Swal.fire({
              title: "Congratulation!",
              text: "Cash Flow has been saved!",
              icon: "success",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "OK",
            }).then(() => {
              this.router.push({
                path: `/customers/details/${this.cstmr_id}/financial-statements/hh/list`,
              });
            });
          });
      }
    },
  },
  async mounted() {
    this.init();
  },
});
